import React, { useState } from "react";
import { SVG_DownArrow } from "../../assets/svgs";
import "./InputSelect.scss";

interface Option {
  value: any;
  text: string;
}

interface Props {
  className?: string;
  placeholder?: string;
  value: string;
  onChange: (e: string) => void;
  onSelectOption: (e: Option | null) => void;
  options: Option[];
  disabled?: boolean;
}

const InputSelect = (props: Props) => {
  const {
    className,
    placeholder,
    value,
    options,
    disabled,
    onChange,
    onSelectOption,
  } = props;

  const [isActive, setIsActive] = useState(false);

  const filteredOptions = options.filter(
    (item) => item.text.toLowerCase().indexOf(value.toLowerCase()) > -1
  );

  return (
    <div
      className={`input-select-wrapper relative ${className || ""} ${
        isActive ? "active is-open" : ""
      } ${disabled ? "disabled" : ""}`}
      onBlur={() => setIsActive(false)}
      tabIndex={1}
    >
      <div className="search-input flex items-center">
        <div className="w-full flex items-center">
          <input
            className="w-full flex-1"
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              onSelectOption(null);
            }}
            onFocus={() => setIsActive(true)}
          />
          {SVG_DownArrow}
        </div>
      </div>
      {isActive && (
        <div className="search-results absolute">
          {filteredOptions.length === 0 ? (
            <p className="flex items-center search-option">No Options...</p>
          ) : (
            <>
              {filteredOptions.map((item, index) => (
                <div
                  className="flex items-center search-option cursor-pointer"
                  key={index}
                  onMouseDown={() => {
                    onChange(item.text);
                    onSelectOption(item);
                  }}
                >
                  {item.text}
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default InputSelect;
