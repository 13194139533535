import axios, { AxiosRequestConfig } from "axios";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (confA: AxiosRequestConfig) => {
    const conf = confA as MAxiosRequestConfig;
    // const accessToken = getAccessToken();

    // if (accessToken) {
    //   conf.headers = conf.headers || {};
    //   conf.headers.authorization = `Bearer ${accessToken}`;
    // }

    return conf;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) =>
    // Edit response config
    response.data,
  (error) => {
    console.log("API Error", error);

    return Promise.reject(error);
  }
);

export default axiosInstance;

export interface MAxiosCustomConfig {
  // custon config to add
}

export interface MAxiosRequestConfig extends AxiosRequestConfig {
  custom?: MAxiosCustomConfig;
}

export async function apiGet<T = any>(
  resource: string,
  config?: MAxiosRequestConfig
) {
  return axiosInstance.get<T>(resource, config);
}

export async function apiPost<T = any>(
  resource: string,
  data?: any,
  config?: MAxiosRequestConfig
) {
  return axiosInstance.post<T>(resource, data, config);
}

export async function apiDelete<T = any>(
  resource: string,
  config?: MAxiosRequestConfig
) {
  return axiosInstance.delete<T>(resource, config);
}

export async function apiPatch<T = any>(
  resource: string,
  data?: any,
  config?: MAxiosRequestConfig
) {
  return axiosInstance.patch<T>(resource, data, config);
}

export async function apiPut<T = any>(
  resource: string,
  data?: any,
  config?: MAxiosRequestConfig
) {
  return axiosInstance.put<T>(resource, data, config);
}

export async function apiUpload<T = any>(
  resource: string,
  data?: any,
  config?: MAxiosRequestConfig,
  progressCallback?: (progress: number) => void
) {
  return axiosInstance.post<T>(resource, data, {
    ...config,
    headers: {
      ...config?.headers,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const uploadPercentage = parseInt(
        String(
          Math.round(
            ((progressEvent.loaded / progressEvent.total) * 100) as number
          )
        ),
        10
      );

      if (progressCallback) {
        progressCallback(uploadPercentage);
      }
    },
  });
}
