import React, { useEffect, useState } from "react";
import { SVG_DownArrow, SVG_Search } from "../../assets/svgs";
import "./SearchInput.scss";

interface Option {
  value: any;
  text: string;
}

interface Props {
  className?: string;
  placeholder?: string;
  value: string;
  onChange: (e: string) => void;
  onSelectOption?: (e: Option) => void;
  api: any;
}

const SearchInput = (props: Props) => {
  const { className, placeholder, value, api, onChange, onSelectOption } =
    props;

  const [isActive, setIsActive] = useState(false);
  const [options, setOptions] = useState<Array<Option>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      value.length > 0 ? searchResult() : setOptions([]);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  const searchResult = async () => {
    setLoading(true);
    let result = await api(value);
    setLoading(false);
    setOptions(result);
  };

  const selectOption = (item: Option) => {
    onChange(item.text);
    if (onSelectOption) {
      onSelectOption(item);
    }
  };

  return (
    <div
      className={`search-input-wrapper relative ${className || ""} ${
        isActive ? "active" : ""
      } ${isActive && (loading || options.length > 0) ? "is-open" : ""}`}
      onBlur={() => setIsActive(false)}
      tabIndex={1}
    >
      <div className="search-input flex items-center">
        <div className="w-full flex items-center">
          {SVG_Search}
          <input
            className="flex-1"
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onFocus={() => setIsActive(true)}
          />
          {SVG_DownArrow}
        </div>
      </div>
      {isActive && (loading || options.length > 0) && (
        <div className="search-results absolute">
          {loading ? (
            <p className="flex items-center search-option">Loading...</p>
          ) : (
            options.length > 0 && (
              <>
                {options.map((item, index) => (
                  <div
                    className="flex items-center search-option cursor-pointer"
                    key={index}
                    onMouseDown={() => selectOption(item)}
                  >
                    {item.text}
                  </div>
                ))}
              </>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SearchInput;
