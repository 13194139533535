import React from "react";
import Spinner from "../Spinner";
import "./Button.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  children: JSX.Element | JSX.Element[];
}

const Button = (props: Props) => {
  const { className, onClick, loading, disabled, children } = props;

  return (
    <button
      className={`button-component ${className}`}
      onClick={() => onClick()}
      disabled={loading || disabled}
    >
      {loading ? <Spinner /> : children}
    </button>
  );
};

export default Button;
