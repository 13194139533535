import React from "react";
import { SVG_CheckMark } from "../../assets/svgs";
import "./CheckBox.scss";

interface Props {
  className?: string;
  label?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const CheckBox = (props: Props) => {
  const { className, label, checked, disabled, onChange } = props;

  return (
    <div
      className={`check-box-wrapper flex items-center cursor-pointer ${className} ${
        disabled ? "disabled" : ""
      }`}
      onClick={() => onChange()}
    >
      <div className="check-box flex items-center justify-center">
        {checked && SVG_CheckMark}
      </div>
      {label && <p>{label}</p>}
    </div>
  );
};

export default CheckBox;
