import React from "react";
import { NavLink } from "react-router-dom";
import "./footer.scss";

export default function Footer() {
  return (
    <div className="footer flex flex-sm-col justify-between">
      <p>
        Copyright © 1994 - {new Date().getFullYear()} GIDEON Informatics, Inc.
        All Rights Reserved.
      </p>
      <div className="footer__links flex items-center">
        <NavLink to="/">Terms & Conditions</NavLink>
        <NavLink to="/">Contact Us</NavLink>
      </div>
    </div>
  );
}
