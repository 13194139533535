import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AppRoutes from "./config/routes";

function App() {
  return (
    <main>
      <Header />
      <div className="main-body">
        <AppRoutes />
      </div>
      <Footer />
    </main>
  );
}

export default App;
