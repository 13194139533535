import React from "react";
import "./header.scss";
import logo from "../../assets/logo.png";
import actress from "../../assets/header_actress.png";
import athlete from "../../assets/header_athlete.png";
import terrorist from "../../assets/header_terrorist.png";
import model from "../../assets/header_model.png";
import mayor from "../../assets/header_mayor.png";
import showman from "../../assets/header_showman.png";
import dancer from "../../assets/header_dancer.png";
import thief from "../../assets/header_thief.png";
import { NavLink } from "react-router-dom";

export default function Header() {
  return (
    <div className="header">
      <div className="h-full lg-body mx-auto flex items-center justify-center">
        <div className="header-links flex items-center justify-between show-web">
          <img src={actress} alt="header_img_1" />
          <img src={athlete} alt="header_img_1" />
          <img src={terrorist} alt="header_img_1" />
          <img src={model} alt="header_img_1" />
        </div>
        <NavLink to="/">
          <img src={logo} alt="logo" className="logo" />
        </NavLink>
        <div className="header-links flex items-center justify-between show-web">
          <img src={mayor} alt="header_img_1" />
          <img src={showman} alt="header_img_1" />
          <img src={dancer} alt="header_img_1" />
          <img src={thief} alt="header_img_1" />
        </div>
      </div>
    </div>
  );
}
