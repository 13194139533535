import {
  disease,
  patient,
  patientSuggestion,
  profession,
} from "../types/patient";
import { apiGet } from "./axios";

export const getProfessions = async () => {
  const res = await apiGet<Array<profession>>(`/api/vipatients/professions`);
  return res.data;
};

export const getDiseases = async () => {
  const res = await apiGet<Array<disease>>(`/api/vipatients/diseases`);
  return res.data;
};

export const getPatientBio = async (patientId: number) => {
  const res = await apiGet<patient>(`/api/vipatients/${patientId}/bio`);
  return res.data;
};

export const getPatientSuggestion = async (name: string) => {
  const res = await apiGet<Array<patientSuggestion>>(
    `/api/vipatients/suggestions?name=${name}`
  );
  return res.data.map((item) => ({ value: item.patient_id, text: item.name }));
};

export const getPatientList = async (
  name: string,
  profession: string,
  disease: string,
  fatality: string,
  from: number | "",
  to: number | ""
) => {
  const res = await apiGet<Array<patient>>(
    `/api/vipatients/filter?name=${name}&profession_code=${profession}&disease_code=${disease}&fatality=${fatality}&from=${from}&to=${to}`
  );
  return res.data.filter((item) => item.first_name || item.last_name);
};
