import React from "react";
import "./Spinner.scss";

interface Props {
  className?: string;
}

const Spinner = (props: Props) => {
  const { className } = props;

  return <div className={`spinner ${className || ""}`}></div>;
};

export default Spinner;
